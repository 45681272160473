import { graphql, useStaticQuery } from "gatsby"

export interface UseMarkdownPage {
  lastupdate: string
  locale: string
  html: string
  title: string
}

export const useMarkdownPage = (
  slug: string,
  locale: string = "en"
): UseMarkdownPage => {
  const result = useStaticQuery(
    graphql`
      {
        allMarkdownRemark {
          nodes {
            html
            frontmatter {
              lastupdate
              locale
              slug
              title
            }
          }
        }
      }
    `
  )

  const data = result.allMarkdownRemark.nodes.find(
    (node: any) =>
      (slug.includes(node.frontmatter.slug) ||
        (slug + "/").includes(node.frontmatter.slug)) &&
      node.frontmatter.locale === locale
  )

  if (data) {
    return {
      ...data.frontmatter,
      html: data.html,
    }
  }

  return {} as UseMarkdownPage
}
