import { FormattedDate, FormattedMessage } from "gatsby-plugin-intl"
import React, { FC } from "react"

export interface LastUpdateProps {
  date: string
}

export const LastUpdate: FC<LastUpdateProps> = ({ date }) => {
  return (
    <FormattedMessage
      defaultMessage="Last update: {0}"
      id="components.LastUpdate"
      values={{
        0: <FormattedDate value={date} />,
      }}
    />
  )
}
